import * as React from 'react';
import { Card } from './Card';

interface IImageCardProps {
  headerText?: string,
  src: string,
  imageWidth: string,
}

export class RightImageCard extends React.Component<IImageCardProps, {}> {
  render() {
    return (
      <>
        <Card headerText={this.props.headerText} >
          <img src={this.props.src} style={{
            float: "right",
            marginLeft: "32px",
            marginBottom: "32px",
            maxWidth: "100%",
            width: this.props.imageWidth,
          }} />
          <div style={{
            minWidth: "220px",
            display: "flex"
          }}>
            <div>
              {this.props.children}
            </div>
          </div>
        </Card>
      </>
    )
  }
}

export class LeftImageCard extends React.Component<IImageCardProps, {}> {
  render() {
    return (
      <>
        <Card headerText={this.props.headerText} >
          <img src={this.props.src} style={{
            float: "left",
            marginRight: "32px",
            marginBottom: "32px",
            maxWidth: "100%",
            width: this.props.imageWidth,
          }} />
          <div style={{
            minWidth: "220px",
            display: "flex"
          }}>
            <div>
              {this.props.children}
            </div>
          </div>
        </Card>
      </>
    )
  }
}