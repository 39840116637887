import React from 'react';
import { Routes, Route } from "react-router-dom";
import ContactView from './Contact/ContactView';
import ImpressumView from './Impressum/ImpressumView';
import { Layout } from './lib/Layout';
import MeView from './Me/MeView';

export default class App extends React.Component<{}, {}> {
  render() {
    return <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<MeView />} />
          <Route path="/contact" element={<ContactView />} />
          <Route path="/impressum" element={<ImpressumView />} />
        </Route>
      </Routes>
    </>;
  }
}
