import * as React from 'react';
import { Text } from '@fluentui/react/lib/Text';

interface ICardProps {
  headerText?: string
}

export class Card extends React.Component<ICardProps, {}> {
  render() {
    return (
      <>
        <div style={{
          backgroundColor: "#EEE",
          scrollSnapAlign: "start",
          display: "flex",
          minHeight: "80%",
          padding: "0px",
          justifyContent: "center",
        }}>

          <div style={{
            //background: 'linear-gradient(-60deg, #000, #000)',
            color: "#222",
            //marginTop: "6vw", // danamisch? warum breite?
            marginTop: "20px", // danamisch? warum breite?
            marginBottom: "40px",
            width: "100%",
            maxWidth: "800px",
            maxHeight: "600px",
            display: 'flex',
            flexDirection: "column",
          }}>
            <section style={{
              padding: "40px",
              overflowX: "clip",
              flex: "1 1 auto",
              display: 'flex',
              flexDirection: "column",
            }}>
              {this.props.headerText && <div style={{
                marginBottom: 28,
                flex: "0 1 auto",
                textDecoration: "underline solid #000",
                textUnderlineOffset: "8px",
              }}><Text variant={'xLarge'} style={{ color: "#222", }} >{this.props.headerText}</Text></div>}
              <div style={{
                flex: "1 1 auto",
                display: 'flex',
                flexDirection: "row",
                alignItems: "center",
              }}>
                <div style={{ maxWidth: "100%", }}>
                  {this.props.children}
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    )
  }
}