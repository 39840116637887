import React from 'react';
import { Card } from '../lib/Card';
import { Text } from '@fluentui/react/lib/Text';
import { Link } from 'react-router-dom';

import logo from './foto.jpg';
import zusammenarbeit_klein from './zusammenarbeit_klein.jpg';
import graduation from './graduation.jpg';
import twitter from './twitter.png';
import microsoft from './microsoft-certified.svg';
import { LeftImageCard, RightImageCard } from '../lib/ImageCard';

export default class MeView extends React.Component<{}, {}> {
  render() {
    return <>
      <LeftImageCard
        headerText={"Softwareentwicklung seit 1999"}
        src={logo}
        imageWidth="200px"
      >
        <Text variant={'xLarge'} style={{ color: "#000", fontWeight: "bold" }}>Bernd Herzog MSc</Text>

        <p>IT-Beratung<br />
          Softwareentwicklung<br />
          Softwarearchitektur<br />
          Computersicherheit<br />
          Machine Learning / KI</p>
        <p>Freiberufler / Einzelunternehmer</p>
        <p>Standort Wien</p>
      </LeftImageCard>

      <RightImageCard
        headerText={"Projektunterstützung"}
        src={zusammenarbeit_klein}
        imageWidth="300px"
      >
        <p>Als Team-Player bin ich immer auf der Suche nach spannenden Projekten, die agil umgesetzt werden.</p>
        <p>Ich bin spezialsiert auf C# &amp; C / C++ und kenne die üblichen Frameworks. Ob ich in Ihr Projekt passe, können wir am besten in einem kurzen Gespräch herausfinden.</p>
        <p><Link to="/contact" onClick={(() => { document.getElementById("scrollDiv")?.scrollTo(0, 0); }).bind(this)} style={{ color: "#D00" }}>Kontaktieren</Link> Sie mich
          einfach, wenn Sie Fragen haben oder an einer Zusammenarbeit interresiert sind.</p>
      </RightImageCard>

      <LeftImageCard
        headerText={"Microsoft - Zertifiziert"}
        src={microsoft}
        imageWidth="200px"
      >
        <p>Microsoft Certified Professional</p>
        <p>Microsoft Specialist: Programming in C#</p>
        <p>Microsoft Certified Solutions Developer: App Builder</p>
        <p>Microsoft Certified Solutions Associate: Web Applications</p>
      </LeftImageCard>

      <RightImageCard
        headerText={"Ausbildung"}
        src={graduation}
        imageWidth="300px"
      >
        <p>Bachelor in Informatik an der Fachhochschule Dortmund</p>
        <p>Master in Computersicherheit und Informationsmanagement an der Fachhochschule Technikum Wien</p>
      </RightImageCard>

      <Card headerText={"Social Media"} >
        <img src={twitter} style={{
          float: "left",
          paddingRight: "32px",
          paddingBottom: "32px",
          maxWidth: "100%",
          width: "300px",
          margin: "-40px",
        }} />
        <div style={{
          minWidth: "220px",
          display: "flex"
        }}>
          <div>
            <p><a href="https://github.com/bernd-herzog" target="_blank" style={{ color: "#37F" }}>Github </a>Profil</p>
            <p><a href="https://at.linkedin.com/in/bernd-herzog-b4333a106" target="_blank" style={{ color: "#37F" }}>Linked In </a>Profil</p>
            <p><a href="https://www.xing.com/profile/Bernd_Herzog11/cv" target="_blank" style={{ color: "#37F" }}>Xing </a>Profil</p>
            <p>Google<a href="https://play.google.com/store/search?q=pub%3ABernd%20Herzog&c=apps" target="_blank" style={{ color: "#37F" }}> Play Store</a></p>
          </div>
        </div>
      </Card>
    </>
  }
}
