import * as React from 'react';
import { IconButton } from '@fluentui/react';

const card: React.CSSProperties = {
  padding: 0,
  margin: 0,
  maxWidth: 800,
  position: "absolute",
  top: "45px",
  right: "0px",
  zIndex: 0,
};
const cardBg: React.CSSProperties = {
  padding: 28,
  margin: 28,
  //backgroundColor: 'red',
  background: "radial-gradient(circle, #AAA, #111)",
  borderRadius: "50%",

  position: "fixed",
  top: "40px",
  right: "6px",
  width: "600px",
  height: "600px",
  transform: "translate(60%,-50%)",
  zIndex: -1,
};


interface IBurgerMenuButtonProps {
  menuOpen: boolean,
  onClick: () => void,
}

export default class BurgerMenuButton extends React.Component<IBurgerMenuButtonProps, {}> {
  render() {
    return (
      <>
        <IconButton
          toggle
          style={{ marginRight: "12px", zIndex: 1, color: "#222", backgroundColor: "transparent" }}
          iconProps={{
            iconName: this.props.menuOpen ? 'Clear' : 'GlobalNavButton', styles: {
              root: { fontSize: "2em", },
            }
          }}
          onClick={(() => { this.props.onClick() }).bind(this)}
        />

        {
          this.props.menuOpen && <>
            <div style={card}>
              {this.props.children}
            </div>
            <div style={cardBg}></div>
          </>
        }
      </>
    )
  }
}
