import * as React from 'react';
import { Link, Outlet } from 'react-router-dom';
import MediaQuery from 'react-responsive'
import BurgerMenuButton from './BurgerMenuButton';

interface ILayoutState {
  menuOpen: boolean
}

const listItemStyle = {
  listStyle: "none",
  margin: "0 1rem",
  color: "blue"
};

export class Layout extends React.Component<{}, ILayoutState> {
  constructor(props: any) {
    super(props);

    this.state = {
      menuOpen: false,
    };
  }

  render() {
    return (
      <>
        <div style={{
          display: "flex",
          flexFlow: "column",
          height: "100vh",
        }}>
          <header style={{
            flex: "0 1 auto",
            background: "linear-gradient(to left, #DDD, #FFF)",
            zIndex: 0,
            color: "#222",
            height: "50px",
            justifyContent: "space-between",
            alignItems: "center",
            display: "flex",
            flexDirection: "row"

          }}>
            <h1 style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              fontSize: "1.3em",
              marginLeft: 7,
              zIndex: 1
            }}>Bernd Herzog - IT Dienstleistungen</h1>

            <MediaQuery minWidth={911}>
              <ul style={{
                display: "flex",
              }}>
                <li style={listItemStyle}><Link to="/" onClick={(() => { document.getElementById("scrollDiv")?.scrollTo(0, 0); }).bind(this)}>Home</Link></li>
                <li style={listItemStyle}><Link to="/contact" onClick={(() => { document.getElementById("scrollDiv")?.scrollTo(0, 0); }).bind(this)}>Kontakt</Link></li>
                <li style={listItemStyle}><Link to="/impressum" onClick={(() => { document.getElementById("scrollDiv")?.scrollTo(0, 0); }).bind(this)}>Impressum</Link></li>
              </ul>
            </MediaQuery>
            <MediaQuery maxWidth={910}>
              <BurgerMenuButton
                menuOpen={this.state.menuOpen}
                onClick={(() => { this.setState({ menuOpen: !this.state.menuOpen }) }).bind(this)}>
                <ul style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "right"
                }}>
                  <li style={{ ...listItemStyle, marginTop: "14px", fontSize: "2em" }}><Link to="/" onClick={(() => { this.setState({ menuOpen: false }); document.getElementById("scrollDiv")?.scrollTo(0, 0); }).bind(this)}>Home</Link></li>
                  <li style={{ ...listItemStyle, marginTop: "14px", fontSize: "2em" }}><Link to="/contact" onClick={(() => { this.setState({ menuOpen: false }); document.getElementById("scrollDiv")?.scrollTo(0, 0); }).bind(this)}>Kontakt</Link></li>
                  <li style={{ ...listItemStyle, marginTop: "14px", fontSize: "2em" }}><Link to="/impressum" onClick={(() => { this.setState({ menuOpen: false }); document.getElementById("scrollDiv")?.scrollTo(0, 0); }).bind(this)}>Impressum</Link></li>
                </ul>
              </BurgerMenuButton>
            </MediaQuery>
          </header>
          <section
            id="scrollDiv"
            style={{
              flex: "1 1 auto",
              overflowY: "scroll",
              scrollSnapType: "y mandatory",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}>
            <Outlet />
          </section>
        </div>
      </>
    )
  }
}