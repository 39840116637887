import React from 'react';
import { Card } from '../lib/Card';
import { QuestionCalloutWrapper } from '../lib/QuestionCallout';
import { PrimaryButton, Stack, StackItem, TextField } from '@fluentui/react';

export default class ContactView extends React.Component<{}, {
  message: string,
  result?: boolean,
}> {

  constructor(props: any) {
    super(props);
    this.state = { message: "" };
  }

  render() {
    return <>
      <Card headerText={"Kontakt"} >

        {this.state.result == undefined && <Stack tokens={{ childrenGap: 15 }}>
          <TextField
            label="Bitte hinterlassen Sie mir über das Formular eine Nachricht."
            multiline={true}
            rows={20}
            value={this.state.message}
            onChange={(e, newValue) => { this.setState({ message: newValue ?? "" }) }}

          />
          <StackItem align="end">
            <QuestionCalloutWrapper
              titleText={'Senden'}
              questionText={'Vergessen Sie nicht Ihre Kontaktdaten anzugeben. Möchten Sie die Nachricht absenden?'}
              okText={'Senden'}
              cancelText={'Abbrechen'}
              onOkPressed={() => {

                const requestOptions = {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/json' },
                  body: JSON.stringify({ message: this.state.message, submit: "submit" })
                };

                fetch('https://www.herzog-it.at/send_message.php', requestOptions)
                  .then(response => response.json())
                  .then(data => this.setState({ result: data == "OK" }))
                  .catch(e => this.setState({ result: false }));

                this.setState({});
              }}
              disabled={this.state.message == ""}
            >

              <PrimaryButton
                text="Senden"
                title="Senden"
                ariaLabel="Senden"
                disabled={this.state.message == ""}
              />

            </QuestionCalloutWrapper>
          </StackItem>
        </Stack>}

        {this.state.result == true && <>
          <p>Vielen Dank für Ihre Nachricht.</p>
        </>}

        {this.state.result == false && <>
          <p>Leider ist ein Fehler aufgetreten.</p>

          <PrimaryButton
            text="Zurück zur Nachricht"
            title="Retry"
            ariaLabel="Retry"
            onClick={() => { this.setState({ result: undefined }) }}
          />
        </>}

      </Card>
    </>
  }
}
